import Vue from 'vue'
import App from './App.vue'
import './common/api/api'
import router from './router'
import store from './store'
import './common/api/interceptors'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import VueTelInputVuetify from 'vue-tel-input-vuetify'
import Vuelidate from 'vuelidate'
import PopupLogin from '@oarepo/vue-popup-login'
import CompositionApi from '@vue/composition-api'
import DaySpanVuetify from 'dayspan-vuetify'
import VueCookies from 'vue-cookies'
// import "vuetify/dist/vuetify.min.css"
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css'

Vue.config.productionTip = false

// Style
require('./assets/Style/style.sass')

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

Vue.use(VueTelInputVuetify, {
  vuetify
})

Vue.use(Vuelidate)
Vue.use(DaySpanVuetify, {
  methods: {
    getDefaultEventColor: () => '#007a33'
  }
})

Vue.use(CompositionApi)

Vue.use(PopupLogin, {
  router
})

Vue.use(VueCookies)

// Vue instance
new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
