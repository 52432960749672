const state = {
  step: 1,
  status: String
}

const actions = {
  INCREMENT_STEP ({ commit }) {
    commit('INCREMENT')
  },
  LEAVE_AUTH_STEP ({ commit }) {
    commit('LEAVE_AUTH_STEP')
  },
  DECREMENT_STEP ({ commit }) {
    commit('DECREMENT')
  },
  SAVE_INFORMATION ({ commit }, payload) {
    return this._vm.$vvtpApi
      .post('/auth/registerinformation/', {
        data: payload
      })
      .then(response => {
        commit('INCREMENT')
        return Promise.resolve(response)
      })
      .catch(error => {
        commit('ENROLL_ERROR', error)
        return Promise.reject(error)
      })
  },
  SAVE_BANKING ({ commit }, payload) {
    return this._vm.$vvtpApi
      .post('/auth/registerbanking/', {
        data: payload
      })
      .then(response => {
        commit('INCREMENT')
        return Promise.resolve(response)
      })
      .catch(error => {
        commit('ENROLL_ERROR', error)
        return Promise.reject(error)
      })
  },
  SAVE_FINAL ({ commit }, payload) {
    return this._vm.$vvtpApi
      .post('/auth/registerfinal/', {
        data: payload
      })
      .then(response => {
        commit('INCREMENT')
        return Promise.resolve(response)
      })
      .catch(error => {
        commit('ENROLL_ERROR', error)
        return Promise.reject(error)
      })
  }
}

const mutations = {
  INCREMENT (state) {
    state.step += 1
  },
  DECREMENT (state) {
    state.step -= 1
  },
  LEAVE_AUTH_STEP (state) {
    state.step = 2
  },
  ENROLL_ERROR (state, error) {
    state.status = error
  }
}

export default {
  state,
  actions,
  mutations
}
