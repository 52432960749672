const state = {
  PartnerList: []
}

const actions = {
  GET_PARTNERS ({ commit }) {
    return this._vm.$vvtpApi
      .get('/partners/')
      .then(r => {
        commit('SET_PARTNERS', r.data.data)
      })
      // .catch(err => {
      // })
  }
}

const mutations = {
  SET_PARTNERS (state, data) {
    state.PartnerList = data
  }
}

export default {
  state,
  actions,
  mutations
}
