import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const AUTHENTICATE_URL = '/oidc/authenticate/'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: {
        title: 'Vereniging voor Technische Physica'
      }
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      beforeEnter (to, from, next) {
        window.location.href = AUTHENTICATE_URL
      }
    },
    {
      path: '/Me',
      name: 'Me',
      component: () => import('./views/Me.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/turf',
      name: 'turf',
      component: () => import('./views/Turf.vue')
    },
    {
      path: '/committees',
      name: 'committees',
      component: () => import('./views/CommitteeList.vue')
    },
    {
      path: '/committee/:id(\\d+)',
      name: 'committee-detail',
      component: () => import('./views/CommitteeDetail.vue')
    },
    {
      path: '/activity/:id(\\d+)',
      name: 'activity-detail',
      component: () => import('./views/ActivityDetail.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('./views/Registration.vue')
    },
    {
      path: '/article/:name',
      name: 'article',
      component: () => import('./views/Articles.vue')
    },
    {
      path: '/album/:id(\\d+)',
      name: 'album',
      component: () => import('./views/Album.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/albums',
      name: 'albums',
      component: () => import('./views/Albums.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('./views/Calendar.vue')
    },
    {
      path: '/termsandconditions',
      name: 'termsandconditions',
      component: () => import('./views/Termsandconditions.vue')
    },
    {
      path: '/failure',
      name: 'failure',
      component: () => import('./views/Failure.vue')
    },
    {
      path: '/404',
      name: '404',
      component: () => import('./views/NotFound.vue')
    },
    {
      path: '/:id(\\d+)',
      name: 'activity-overview',
      component: () => import('./views/Home.vue')
    },
    {
      path: '*',
      name: '404',
      component: () => import('./views/NotFound.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth)
  if (requiresAuth) {
    store.dispatch('GET_AUTH_STATUS')
    // eslint-disable-next-line
    console.log(store.getters.isLoggedIn)
    if (store.getters.isLoggedIn) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

export default router
