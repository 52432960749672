/**
* Initialises the style of the menu component based on the window and container.
* Fired when the document is ready and when the window is resized.
* @author Matthias Flor
* @since 03/2020
*/
export function initialisePosition () {
  function init () {
    const device = window.innerWidth
    const contnr = document.getElementsByClassName('main-content')[0].offsetWidth
    const space = ((device - contnr) / 2) + 'px'
    document.getElementsByClassName('header-logo')[0].style.marginLeft = space
    document.getElementById('nav-bar').children[0].style.paddingLeft = space
    document.getElementById('nav-bar').children[0].style.paddingRight = space
  }
  document.addEventListener('DOMContentLoaded', function () { init() })
  window.addEventListener('resize', function () { init() })
}

/**
* Bulma recommended addition to the navigation bar to make the burger dropdown
* work properly. Cf. Bulma Navbar documentation.
*/
export function navbarBurger () {
  document.addEventListener('DOMContentLoaded', () => {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target
          const $target = document.getElementById(target)
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active')
          $target.classList.toggle('is-active')
        })
      })
    }
  })
}
