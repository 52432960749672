<template>

  <v-list-item v-if="element.children.length === 0" :to="to" :href="href">
    <v-list-item-title>{{ $t('title') }}</v-list-item-title>
  </v-list-item>

  <v-list-group v-else :key="element.id" no-action>
    <template v-slot:activator>
      <v-list-item-title>{{ $t('title') }}</v-list-item-title>
    </template>
    <MenuChildMobile v-for="j in element.children" :key="j.id" :element="j">
    </MenuChildMobile>
  </v-list-group>

</template>

<script>
import MenuChildMobile from './ChildMobile.vue'

export default {
  name: 'MenuParentMobile',
  props: ['element'],
  components: {
    MenuChildMobile
  },
  computed: {
    href: function () {
      if (this.element.external_link) {
        return this.element.link_to
      } else {
        return ''
      }
    },
    to: function () {
      if (!this.element.external_link) {
        return this.element.link_to
      } else {
        return ''
      }
    }
  },
  i18n: {},
  created: function () {
    this.$i18n.setLocaleMessage('en', this.element.messages.en)
    this.$i18n.setLocaleMessage('nl', this.element.messages.nl)
  }
}
</script>
