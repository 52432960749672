<template>
  <v-app>
    <main>
      <Header/>
      <router-view/>
      <Footer/>
    </main>
  </v-app>
</template>

<script>
import Header from './components/Header/Header.vue'
import Footer from './components/Footer/Footer.vue'
export default {
  name: 'home',
  mounted () {
    this.$store.dispatch('GET_AUTH_STATUS')
  },
  components: {
    Header,
    Footer
  }
}
</script>
