<!-- Single sub-menu element -->

<template>

  <v-list-item
    :href="href"
    :key="element.id"
    no- tileaction color="primary"
  >
    <v-list-item-content>
      <v-list-item-title>{{ $t('title') }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>

</template>

<script>
export default {
  name: 'MenuChild',
  props: ['element'],
  i18n: {},
  computed: {
    href: function () {
      if (!this.element.external_link) {
        return new URL(this.element.link_to, window.location.origin).toString()
      } else {
        return this.element.link_to
      }
    }
  },
  created: function () {
    this.$i18n.setLocaleMessage('en', this.element.messages.en)
    this.$i18n.setLocaleMessage('nl', this.element.messages.nl)
  }
}
</script>
