<template>
  <v-list-item :key="element.id" :to="to" :href="href">
    <v-list-item-title>{{ $t('title') }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'MenuChildMobile',
  props: ['element'],
  computed: {
    href: function () {
      if (this.element.external_link) {
        return this.element.link_to
      } else {
        return ''
      }
    },
    to: function () {
      if (!this.element.external_link) {
        return this.element.link_to
      } else {
        return ''
      }
    }
  },
  i18n: {},
  created: function () {
    this.$i18n.setLocaleMessage('en', this.element.messages.en)
    this.$i18n.setLocaleMessage('nl', this.element.messages.nl)
  }
}
</script>
