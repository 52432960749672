import Vue from 'vue'
import Vuex from 'vuex'
import activity from './modules/activity'
import auth from './modules/auth'
import turf from './modules/turf'
import menu from './modules/menu'
import userinfo from './modules/userinfo'
import committee from './modules/committee'
import photo from './modules/photo'
import enroll from './modules/enroll'
import banner from './modules/banner'
import carreer from './modules/carreer'
import declarations from './modules/declarations'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    activity: activity,
    login: auth,
    turf: turf,
    menu: menu,
    userinformation: userinfo,
    committee: committee,
    photo: photo,
    enroll: enroll,
    banner: banner,
    carreer: carreer,
    finance: declarations
  }
})
