import Vue from 'vue'

const state = {
  transactions: [],
  products: [],
  selected: {}
}

const actions = {
  GET_PRODUCTS ({ commit }) {
    return this._vm.$vvtpApi
      .get('/turf/products/')
      .then(r => r.data.data)
      .then(products => {
        commit('SET_PRODUCTS', products)
      })
      // .catch(error => {
      // })
  },
  ADD_SELECTED ({ commit }, payload) {
    commit('SET_SELECTED', payload)
  },
  CLEAR_SELECTED ({ commit }) {
    commit('CLEAR_SELECTED')
  },
  GET_TRANSACTIONS ({ commit }) {
    return this._vm.$vvtpApi
      .get('/turf/transactions/')
      .then(r => r.data.data)
      .then(transactions => {
        commit('SET_TRANSACTIONS', transactions)
      })
      // .catch(error => {
      // })
  }
}

const mutations = {
  SET_PRODUCTS (state, products) {
    state.products = products
  },
  SET_SELECTED (state, { product, amount }) {
    Vue.set(state.selected, product, amount)
    if (amount === 0) {
      Vue.delete(state.selected, product)
    }
  },
  CLEAR_SELECTED (state) {
    state.selected = {}
  },
  SET_TRANSACTIONS (state, transactions) {
    state.transactions = transactions
  }
}

export default {
  state,
  actions,
  mutations
}
