const state = {
  menu: []
}

const actions = {
  GET_MENU ({ commit }) {
    this._vm.$vvtpApi
      .get('/web/menu/')
      .then(r => r.data.data)
      .then(menu => {
        commit('SET_MENU', menu)
      })
      // .catch(error => {
      // })
  }
}

const mutations = {
  SET_MENU (state, menu) {
    state.menu = menu
  }
}

export default {
  state,
  actions,
  mutations
}
