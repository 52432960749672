const state = {
  information: ''
}

const actions = {
  GET_USERINFO ({ commit }) {
    return this._vm.$vvtpApi
      .get('/auth/me/')
      .then(response => {
        commit('SET_INFO', response.data.data)
      })
      // .catch(error => {
      // })
  },
  GET_DOCUMENTS ({ commit }) {
    return this._vm.$vvtpApi
      .get('/document/documents/')
      .then(response => {
        commit('SET_DOCUMENTS', response.data.data)
      })
      // .catch(error => {
      // })
  }
}

const mutations = {
  SET_INFO (state, data) {
    state.information = data
  },
  SET_DOCUMENTS (state, data) {
    state.documents = data
  }
}

export default {
  state,
  actions,
  mutations
}
