const state = {
  committees: [],
  committee: []
}

const actions = {
  GET_COMMITTEES ({ commit }) {
    return this._vm.$vvtpApi
      .get('/committee/committees/')
      .then(r => {
        commit('SET_COMMITTEES', r.data.data)
      })
      // .catch(err => {
      // })
  },
  GET_COMMITTEE ({ commit }, payload) {
    return this._vm.$vvtpApi
      .get('/committee/' + payload.id + '/')
      .then(r => {
        commit('SET_COMMITTEE', r.data.data)
      })
      // .catch(err => {
      // })
  },
  UPDATE_INTEREST ({ commit }, payload) {
    return this._vm.$vvtpApi
      .post('/committee/update-interest/', {
        committeeId: payload.committeeId,
        value: payload.value
      })
      .then()
      // .catch(err => {
      // })
  }
}

const mutations = {
  SET_COMMITTEES (state, committees) {
    state.committees = committees
  },
  SET_COMMITTEE (state, committee) {
    state.committee = committee
  }
}

export default {
  state,
  actions,
  mutations
}
