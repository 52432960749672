const state = {
  albums: [],
  album_pages: 1,
  recent_albums: [],
  album: {
    name: '',
    images: [],
    date: '',
    preview_url: ''
  }
}

const actions = {
  GET_ALBUMS ({ commit }, payload) {
    return this._vm.$vvtpApi
      .get('/photo/albums/', { params: { page: payload.page } })
      .then(r => {
        commit('SET_PAGES', r.data.pages)
        commit('SET_ALBUMS', r.data.data)
      })
  },
  GET_RECENT_ALBUMS ({ commit }) {
    return this._vm.$vvtpApi
      .get('/photo/recent_albums/')
      .then(r => {
        commit('SET_RECENT_ALBUMS', r.data.data)
      })
  },
  GET_ALBUM ({ commit }, payload) {
    return this._vm.$vvtpApi
      .get('/photo/album/' + payload.id + '/')
      .then(r => {
        commit('SET_ALBUM', r.data.data)
      })
  }
}

const mutations = {
  SET_PAGES (state, pages) {
    state.album_pages = pages
  },
  SET_ALBUMS (state, albums) {
    state.albums = albums
  },
  SET_RECENT_ALBUMS (state, albums) {
    state.recent_albums = albums
  },
  SET_ALBUM (state, album) {
    state.album = album
  }
}

export default {
  state,
  actions,
  mutations
}
