<!-- Single menu element -->

<template>

  <!-- 1. The element has no dropdown menu: -->
  <v-btn
    v-if="element.children.length === 0"
    :key="element.id"
    tile
    color="primary"
    elevation="0"
    :href="href"
  >
    {{ $t('title') }}
  </v-btn>

  <!-- 2. The element has a dropdown menu: -->
  <v-menu
    v-else
    :key="element.id"
    :close-on-content-click="false"
    open-on-hover
    tile
    offset-y
    bottom
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :key="element.id"
        tile
        color="primary"
        elevation="0"
        v-on="on"
        :href="href"
      >
        {{ $t('title') }}
      </v-btn>
    </template>

    <!-- Dropdown menu-->
    <v-list>
      <MenuChild v-for="j in element.children" :key="j.id" :element="j"></MenuChild>
    </v-list>

  </v-menu>

</template>

<style scoped lang="sass">
  div.v-menu__content
    top: 377px !important
</style>

<script>
import MenuChild from './Child.vue'

export default {
  name: 'MenuParent',
  props: ['element'],
  components: {
    MenuChild
  },
  computed: {
    href: function () {
      if (!this.element.external_link) {
        return new URL(this.element.link_to, window.location.origin).toString()
      } else {
        return this.element.link_to
      }
    },
    clickable: function () {
      return this.element.external_link != null
    }
  },
  i18n: {},
  created: function () {
    this.$i18n.setLocaleMessage('en', this.element.messages.en)
    this.$i18n.setLocaleMessage('nl', this.element.messages.nl)
  }
}
</script>
