// The activity store
// Loads activities and enrolls/unenrolls
// See the backend for more info
const state = {
  activities: [],
  activity: []
}

const actions = {
  GET_ACTIVITIES ({ commit }) {
    return this._vm.$vvtpApi
      .get('/activity/activities/')
      .then(r => r.data.data)
      .then(activities => {
        commit('SET_ACTIVITIES', activities)
      })
      // .catch(error => {
      // })
  },
  GET_ACTIVITY ({ commit }, payload) {
    return this._vm.$vvtpApi
      .get('/activity/' + payload.id + '/')
      .then(r => {
        commit('SET_ACTIVITY', r.data.data)
      })
      // .catch(error => {
      // })
  },
  ENROLL_ACTIVITY ({ commit }, payload) {
    return this._vm.$vvtpApi
      .post('/activity/enroll/', {
        pk: payload.id,
        answers: payload.answers
      })
      .then(r => r.data)
      // .catch(error => {
      // })
  },
  UNENROLL_ACTIVITY ({ commit }, payload) {
    return this._vm.$vvtpApi
      .post('/activity/unenroll/', {
        pk: payload.id
      })
      .then(r => r.data)
      // .catch(error => {
      // })
  }
}

const mutations = {
  SET_ACTIVITIES (state, activities) {
    state.activities = activities
  },
  SET_ACTIVITY (state, activity) {
    state.activity = activity
  }
}

export default {
  state,
  actions,
  mutations
}
