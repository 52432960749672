import Vue from 'vue'
import axios from 'axios'

// function getCookie(name) {
//   let cookieValue = null;
//   if (document.cookie && document.cookie !== '') {
//       const cookies = document.cookie.split(';');
//       for (let i = 0; i < cookies.length; i++) {
//           const cookie = cookies[i].trim();
//           // Does this cookie string begin with the name we want?
//           if (cookie.substring(0, name.length + 1) === (name + '=')) {
//               cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//               break;
//           }
//       }
//   }
//   return cookieValue;
// }
// const csrftoken = getCookie('csrftoken');

// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";
// axios.defaults.headers.common['X-CSRFTOKEN'] = 'u5Xkiiuzd5G0XsmeYct1eEC8gyRtwiWfr59qzkryM2VlIuG7tfI12zie2u3qahsh'
// axios.defaults.headers.common['X-CSRF-TOKEN'] = 'u5Xkiiuzd5G0XsmeYct1eEC8gyRtwiWfr59qzkryM2VlIuG7tfI12zie2u3qahsh'
// axios.defaults.headers.common['X-CSRF-TOKEN'] = csrftoken;

const vvtpApi = axios.create({
  baseURL: '/api',
  withCredentials: true,
  headers: {
    common: {
      Authorization: ''
    }
  }
})

Vue.use({
  install (Vue) {
    Vue.prototype.$vvtpApi = vvtpApi
  }
})

export default vvtpApi
