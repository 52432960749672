<template>
  <div>
    <div class="main-content">
      <div class="component-box">
        <div class="component-box-title-left"><div>Partners</div></div>
        <div class="component-content">
          <div class="columns">
            <Partners v-for="partner in partnerlist" :key="partner.partner_name" :partner="partner"></Partners>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-logo">
        <img src="@/assets/Images/logowhite.svg" alt="White logo"/>
      </div>
      <div>
        <p> Crafted with ♡ by the WeCie.<br> With many thanks to our former members: Ruben Braas, Matthias Flor, Abi Kanagaratnam, Jeroen van de Velde and Lars van Tol<br> All rights reserved © {{ new Date().getFullYear() }} Vereniging voor Technische Physica.
          <br><a href="/article/privacy-policy">Privacy policy</a> | <a href="/termsandconditions">Terms and conditions</a>
        </p>
        <p> Vereniging voor Technische Physica
          <br> Kamer A109 Lorentzweg 1
          <br> 2628 CJ Delft
          <br><a href="mailto:vvtp@vvtp.nl" title="Send an e-mail to the VvTP">vvtp@vvtp.nl</a> | 015 278 6122
        </p>
        <p>
          <a href="/article/contact">Extra contact information</a>
        </p>
      </div>
      <div class="social-media-icons">
        <a href="https://www.facebook.com/groups/5234769393" target="_blank" title="VvTP on Facebook">
          <v-icon large color="#BFD9C9">mdi-facebook</v-icon>
        </a>
        <a href="https://www.instagram.com/vvtp_delft/" target="_blank" title="VvTP on Instagram">
          <v-icon large color="#BFD9C9">mdi-instagram</v-icon>
        </a>
        <a href="https://www.linkedin.com/company/574271/" target="_blank" title="VvTP on LinkedIn">
          <v-icon large color="#BFD9C9">mdi-linkedin</v-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Partners from './Partners.vue'
export default {
  components: { Partners },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  mounted () {
    this.$store.dispatch('GET_PARTNERS')
  },
  computed: {
    partnerlist () {
      return this.$store.state.carreer.PartnerList
    }
  }
}
</script>

<style lang="sass" scoped>
@import "Footer.sass"
</style>
