<template>
  <header>

    <div class="header-main">
      <div class="header-overlaylogo">
        <img src="../../assets/Images/logo.svg"/>
      </div>
      <div class="header-logo">
        <router-link to="/">
          <div>
            <img src="../../assets/Images/logo.svg"/>
          </div>
          <div>
            <span>Vereniging voor</span><br>
            <span>Technische Physica</span>
          </div>
        </router-link>
      </div>
    </div>

    <v-toolbar fixed flat dark color="primary" id="nav-bar">
      <v-menu
        :close-on-content-click="false"
        bottom
        offset-y
        tile
        min-width="100%"
      >
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon
            large
            v-on="on"
            class="hidden-md-and-up"
          ></v-app-bar-nav-icon>
        </template>

        <v-list>
          <MenuParentMobile v-for="i in menu" :key="i.id" :element="i">
          </MenuParentMobile>
        </v-list>
      </v-menu>

      <v-toolbar-items class="hidden-sm-and-down">
        <MenuParent v-for="i in menu" :key="i.id" :element="i"></MenuParent>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-btn icon to="/article/website-updates">
        <v-icon>mdi-wrench-outline</v-icon>
      </v-btn>

      <v-btn icon v-if="this.isLoggedIn" to="/Me">
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-btn>

      <v-btn icon v-else-if="this.isSurfConextAuthenticated" to="/register">
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-btn>

      <v-btn icon v-else to="/login">
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-btn>

      <v-btn icon @click="switchLocale()">
        <template v-if="this.$i18n.locale === 'nl'">EN</template>
        <template v-else>NL</template>
      </v-btn>

    </v-toolbar>
  </header>
</template>

<style lang="sass" scoped>
@import "Header.sass"

div.v-menu__content
  top: 368px !important
</style>

<script>
import * as Header from './Header.js'
import MenuParent from './components/Parent.vue'
import MenuParentMobile from './components/ParentMobile.vue'

export default {
  name: 'Header',
  computed: {
    menu: function () {
      return this.$store.state.menu.menu
    },
    isLoggedIn () {
      this.$store.dispatch('GET_AUTH_STATUS')
      return this.$store.getters.isLoggedIn
    },
    isSurfConextAuthenticated () {
      this.$store.dispatch('GET_AUTH_STATUS')
      return this.$store.getters.isSurfConextAuthenticated
    }
  },
  components: {
    MenuParent,
    MenuParentMobile
  },
  mounted () {
    this.$store.dispatch('GET_MENU')
    Header.navbarBurger()
    Header.initialisePosition()
  },
  methods: {
    switchLocale () {
      if (this.$i18n.locale === 'nl') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'nl'
      }
    }
  }
}
</script>
