import vvtpApi from './api'
import store from '../../store'
import router from '../../router'
import Cookies from 'js-cookie'

function createResponseInterceptor () {
  const interceptor = vvtpApi.interceptors.response.use(
    response => response,
    error => {
      if (typeof error.response !== 'undefined') {
        if (error.response.status !== 401) {
          return Promise.reject(error)
        }
      }
      if (!store.getters.isLoggedIn) {
        return Promise.reject(error)
      }
      // When the response code is 401, we try to refresh the token. Eject the
      // interceptor so it does not loop if the token refresh results in a 401.
      vvtpApi.interceptors.response.eject(interceptor)
      return store.dispatch('GET_REFRESH')
        .then(response => {
          error.response.config.headers.Authorization = 'JWT ' + store.state.login.accesstoken
          return vvtpApi(error.response.config)
        })
        .catch(error => {
          router.push({ name: 'login' })
          return Promise.reject(error)
        })
        .finally(createResponseInterceptor)
    }
  )
}

createResponseInterceptor()

function httpGet (theUrl) {
  const xmlHttp = new XMLHttpRequest()
  xmlHttp.open('GET', theUrl, false) // false for synchronous request
  xmlHttp.send(null)
  return xmlHttp.responseText
}

// Test function: never worked.
// function getCsrfToken (config) {
//   vvtpApi.get('/csrftoken/').then(function (response) {
//     console.log(response)
//     console.log(response.data)
//     console.log(response.data.data)
//     console.log(response.data.data.csrf_token)
//     config.headers['X-CSRFToken'] = response.data.data.csrf_token
//     config.headers['X-CSRF-Token'] = response.data.data.csrf_token
//     return response.data.data.csrf_token
//   })
// }

vvtpApi.interceptors.request.use(
  config => {
    if (config.method === 'post' || config.method === 'POST') {
      // console.log(Cookies.get('csrftoken'))
      // var csrftoken = get_csrf_token(config)
      // console.log(csrftoken)
      const obtainedCsrfToken = httpGet('/api/csrftoken/')
      const csrftoken = JSON.parse(obtainedCsrfToken)
      Cookies.set('csrftoken', csrftoken.data.csrf_token)
      Cookies.set('csrf_token', csrftoken.data.csrf_token)
      config.headers['X-CSRFToken'] = csrftoken.data.csrf_token
      config.headers['X-CSRF-Token'] = csrftoken.data.csrf_token
      // console.log(csrftoken);
      // console.log(Cookies.get('csrftoken'))
      // vvtpApi.$cookie.set('csrftoken', csrftoken)
      // config.headers['X-CSRFToken'] = Cookies.get('csrftoken')
      // config.headers['X-CSRF-Token'] = Cookies.get('csrftoken')
    } else {
      // console.log(config.method)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
