<template>
  <div class="column is-one-fifth-desktop is-half-mobile">
    <v-hover v-slot="{ hover }">
      <a :href="partner.partner_url" target="_blank">
        <v-card
          height="70px"
          width="100%"
        >
          <v-img
            contain
            height="70px"
            class="justify-center align-center"
            :src="partner.url"
          ></v-img>
          <v-expand-transition>
            <v-card v-if="hover" class="partner-description">
              <v-card-text>
                {{ truncate($t('description')) }}
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card>
      </a>
    </v-hover>
  </div>
</template>

<style lang="sass" scoped>
@import "Partners.sass"
</style>

<script>
export default {
  name: 'partners',
  props: ['partner'],
  i18n: {},
  // TODO moet nog opgehaald worden & je wil de data importern in Footer en dan als
  // prop doorgeven hier naar toe.
  // data () {
  //   return {
  //     name: 'ASML',
  //     url: 'https://www.asml.com/',
  //     description: 'ASML is een Nederlands hightechbedrijf en de belangrijkste leverancier van machines voor de halfgeleiderindustrie, in het bijzonder steppers en scanners, die worden gebruikt bij het maken van chips. Klanten zijn veelal chipproducenten. Het hoofdkantoor en bedrijvencomplex van ASML staat in Veldhoven, waar zowel research and development als assemblage in cleanrooms plaatsvindt. Het bedrijf heeft tevens zestig servicepunten in veertien landen ter ondersteuning van de plaatsing en levering van machines en reserve-onderdelen.'
  //   }
  // },
  created () {
    const nl = {}
    const en = {}
    this.$i18n.setLocaleMessage('en', Object.assign({}, en, this.partner.messages.en))
    this.$i18n.setLocaleMessage('nl', Object.assign({}, nl, this.partner.messages.nl))
  },
  methods: {
    truncate: function (input) {
      const length = 200
      if (input.length > length) { return input.substring(0, length) + '...' }
      return input
    }
  },
  data () {
    return {
      hover: false
    }
  }
}
</script>
