const state = {
  declarations: []
}

const getters = {
  declarations: state => {
    return state.declarations
  }
}

const actions = {
  GET_DECLARATIONS ({ commit }) {
    return this._vm.$vvtpApi
      .get('/finance/declarations/')
      .then(r => r.data.data)
      .then(declarations => {
        commit('SET_DECLARATIONS', declarations)
      })
      // .catch(error => {
      // })
  },
  ADD_DECLARATION ({ commit }, payload) {
    return this._vm.$vvtpApi
      .post('/finance/add-declaration/', payload)
      .then()
      // .catch(err => {
      // })
  }
}

const mutations = {
  SET_DECLARATIONS (state, declarations) {
    state.declarations = declarations
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
