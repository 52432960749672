const state = {
  BannerImages: []
}

const actions = {
  GET_BANNERS ({ commit }) {
    return this._vm.$vvtpApi
      .get('/posters/')
      .then(r => {
        commit('SET_BANNERS', r.data.data)
      })
      // .catch(err => {
      // })
  }
}

const mutations = {
  SET_BANNERS (state, images) {
    state.BannerImages = images
  }
}

export default {
  state,
  actions,
  mutations
}
